import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo } from 'rxjs/operators';
import {
  NewQuotationDialogFromQuantityComponent,
} from 'shared/components/quotation-dialog-from-quantity/new-quotation-dialog-from-quantity.component';
import { SnackbarService } from 'shared/services/snackbar/snackbar.service';
import { loginSuccess, logout } from 'store/auth/auth.actions';
import { CartItemDTO, CartService, CartAndFavoriteItemCountDTO } from 'tekkeys-common';

import {
  addFromFavoriteToCart,
  addFromQuotationToCart,
  addToCart,
  addToCartFailed,
  addToCartSuccess,
  ApplyPromoCode,
  ApplyPromoCodeFailed,
  ApplyPromoCodeSuccessfully,
  clearCart,
  loadCart,
  loadCartSuccess,
  removeCartItem,
  removeCartItemSuccess,
  setQuantity,
  setQuantityFailed,
  setQuantitySuccess,
  getCartAndFavoriteItemCount,
  getCartAndFavoriteItemCountSuccess,
  getCartAndFavoriteItemCountFailure
} from './cart.actions';

@Injectable()
export class CartEffects {
  init = createEffect(() =>
    this.actions.pipe(ofType(ROOT_EFFECTS_INIT), switchMapTo(of(loadCart())))
  );

  loadCart = createEffect(() =>
    this.actions.pipe(
      ofType(loadCart),
      switchMap(() =>
        this.cartService.get().pipe(
          map((response) => loadCartSuccess({cart: response})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loginSuccess = createEffect(() =>
    this.actions.pipe(ofType(loginSuccess), switchMapTo(of(loadCart())))
  );

  logout = createEffect(() =>
    this.actions.pipe(ofType(logout), switchMapTo(of(clearCart())))
  );

  addToCart = createEffect(() =>
    this.actions.pipe(
      ofType(addToCart),
      switchMap((action) =>
        this.cartService.add(action.productId, action.quantity).pipe(
          map((response) => addToCartSuccess({cartItem: response})),
          catchError((e) => of(addToCartFailed({error: e})))
        )
      )
    )
  );

  setQuantity = createEffect(() =>
    this.actions.pipe(
      ofType(setQuantity),
      switchMap((action) =>
        this.cartService.changeQuantity(action.cartItemId, action.quantity).pipe(
          map((response) => {
            const update: Update<CartItemDTO> = {
              id: response.id,
              changes: response,
            };
            return setQuantitySuccess({ update });
          }),
          catchError((error) => {
            // Action d'échec avec le message d'erreur
            return of(setQuantityFailed({ error: error.message || 'Une erreur est survenue lors de la mise à jour de la quantité.' }));
          })
        )
      )
    )
  );

  setQuantityFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(setQuantityFailed),
        map(() => {}
          /*this.snackbarService.openSnackBar(
            'Ce nom d\'adresse est déjà utilisée!',
            'Fermer',
            'error-snackbar'
          )*/
        )
      ),
    { dispatch: false }
  );

  deleteCartItem = createEffect(() =>
    this.actions.pipe(
      ofType(removeCartItem),
      switchMap((action) =>
        this.cartService.remove(action.id).pipe(
          map((response) => removeCartItemSuccess({id: action.id})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  addFromFavoriteToCart = createEffect(() =>
    this.actions.pipe(
      ofType(addFromFavoriteToCart),
      switchMap((action) =>
        this.cartService.addFromFavorite(action.favoriteId).pipe(
          map((response) => addToCartSuccess({cartItem: response})),
          catchError((e) => of(addToCartFailed({error: e})))
        )
      )
    )
  );

  addFromQuotationToCart = createEffect(() =>
    this.actions.pipe(
      ofType(addFromQuotationToCart),
      switchMap((action) =>
        this.cartService.add(action.productId, action.quantity).pipe(
          map((response) => addToCartSuccess({cartItem: response})),
          catchError(() => {
            this.snackbarService.openSnackBar(
              'une erreur est survenue',

              'error-snackbar'
            );
            return EMPTY;
          })
        )
      )
    )
  );

  // Getting Cart And Favorite Items Count from API
  getCartAndFavoriteItemsCount = createEffect(() =>
  this.actions.pipe(
    ofType(getCartAndFavoriteItemCount),
    switchMap((action) =>
      this.cartService.getCartItemAndFavoriteItemCount().pipe(
        map((response: CartAndFavoriteItemCountDTO) => getCartAndFavoriteItemCountSuccess({itemsCount: response})),
        catchError((e) => of(getCartAndFavoriteItemCountFailure({error: e})))
      )
    )
  )
);

  // promocode
  ApplyPromoCode = createEffect(() =>
    this.actions.pipe(
      ofType(ApplyPromoCode),
      switchMap((action) =>
        this.cartService.applyPromoCode(action.promoCodeValue).pipe(
          map((response: any) => ApplyPromoCodeSuccessfully({promoCode: response})),
          catchError((e) => of(ApplyPromoCodeFailed({error: e})))
        )
      )
    )
  );
  ApplyPromoCodeSuccessfully$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ApplyPromoCodeSuccessfully),
        map(() =>
          this.snackbarService.openSnackBar(
            'Code Promo effectuée avec succés',
            'success-snackbar'
          )
        )
      ),
    { dispatch: false }
  );

  ApplyPromoCodeFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ApplyPromoCodeFailed),
        map((response) => {
          let errorMessage: string;
          switch (response.error.error.message) {
            case 'promo code not found':
              errorMessage = 'Code promo introuvable';
              break;
            case 'promo code already used':
              errorMessage = ' Malheureusement ce code promo a atteint sa limite d\'utilisation!';
              break;
            case 'promo code already exists':
              errorMessage = 'le code promo existe déjà';
              break;
            case 'order price didn\'t reach minimum value to benefit promo code':
              errorMessage = 'le prix de la commande n\'a pas atteint la valeur minimale pour bénéficier du code promo';
              break;
            case 'product has dicount':
              errorMessage = 'le produit a une remise';
              break;
              case 'promo code expired':
              errorMessage = 'Malheureusement ce code promo à expiré ';
              break;
              case 'you reached maximum authorized promo code usage':
              errorMessage = 'Malheureusement vous avez dépassé le nombre d\'utilisation autorisé pour ce code promo!';
              break;
            default:
              errorMessage = 'une erreur est survenue !';
              break;
          }
          this.snackbarService.openSnackBar(
           errorMessage,

            'error-snackbar',
          );
          return errorMessage;
        })),
    { dispatch: false }
  );

  constructor(
    private cartService: CartService,
    private snackbarService: SnackbarService,
    private actions: Actions,
    private dialog: MatDialog,
  ) {
  }

  showDialogBox(cartItemId: number, quantity: number) {
    const data = {
      cartItemId,
      quantity
    };
    this.dialog.open(NewQuotationDialogFromQuantityComponent, {
      data,
      maxWidth: '100%',
    });
  }
}
