import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {AuthTokenDTO, ClientService} from "tekkeys-common";
import {logout} from "store/auth/auth.actions";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store, private clientService: ClientService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem('authToken');
    if (token) {
      // Check if the token is expired
      const isExpired = this.isTokenExpired(token);
      if (isExpired) {
        // If token is expired, logout

          this.store.dispatch(logout())

      }

      // If token is not expired, set it in the request headers
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }

    // Proceed with the request if no token or if the token is valid
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        // Handle HTTP errors
        return throwError(err);
      })
    );
  }

  private isTokenExpired(token: string): boolean {
    const payload = this.decodeToken(token);
    if (!payload || !payload.exp) {
      return true; // Invalid token or no expiration
    }
    const expirationDate = new Date(payload.exp * 1000); // Convert exp to milliseconds
    return expirationDate < new Date(); // Check if expired
  }

  private decodeToken(token: string): any {
    const payload = token.split('.')[1]; // JWTs are in the format: header.payload.signature
    return JSON.parse(atob(payload)); // Decode base64 payload
  }
}
